<script>
  import Explainer from './Explainer.svelte';
  import Header from './Header.svelte';
</script>

<style>
</style>

<div id="app-page">


  <Explainer />
</div>
